@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .form-field {
        @apply w-full !rounded-xl !border !border-solid !border-gray-200 focus-visible:outline-gray-300 !py-2 !px-3 !font-normal !leading-normal !text-[15px] !bg-white !text-black;
    }

    label.form-label {
        @apply text-xs static font-normal font-arial text-black;
    }

    .form-btn-field {
        @apply w-full !rounded-xl !border !border-solid !border-gray-200 focus-visible:outline-gray-300 !py-2 !px-3 !font-normal !leading-normal !text-[15px];
    }
}

@layer base {
    * {
        -webkit-appearance: inherit;
    }

    .uwi-scrollbar::-webkit-scrollbar {
        width: 10px;
    }
    
    .uwi-scrollbar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    
    .uwi-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    input[type="radio"].uwi-input, input[type="checkbox"].uwi-input {
        -webkit-appearance: auto;
    }

    input[type=checkbox].uwi-input:checked:before {
        content: "";
    }

    #uwi-widget-root #static-data {
        @apply leading-6;
    }

    #uwi-widget-root #static-data h2 {
        @apply text-xl mb-4;
    }

    #uwi-widget-root #static-data h3 {
        @apply text-lg mb-3;
    }

    #uwi-widget-root #static-data p {
        @apply mb-4;
    }

    #uwi-widget-root #static-data a {
        @apply text-blue-700 hover:text-blue-500;
    }

    #uwi-widget-root #static-data ul {
        @apply pl-4;
    }

    #uwi-widget-root #static-data ul > li {
        @apply list-disc;
    }
}

.body-overflow-hidden {
    overflow: hidden;
}

#uwi-widget-root label { pointer-events: all; }